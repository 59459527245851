











import { Component, Vue } from "vue-property-decorator";
import __ from "@/shared/helpers/__";
import PageHeader from "@/admin/components/PageHeader.vue";
import FormBase from "@/shared/classes/form/form-base";
import { HttpMethod } from "@/shared/helpers/requests.helper";
import Field, { FieldSizes } from "@/shared/classes/form/field";
import { FieldTypes } from "@/shared/components/form/field-types";
import SearchableField from "@/shared/classes/form/fields/Select/searchable-field";
import RiskTemplateForm from "@/admin/components/risk-templates/RiskTemplateForm.vue";
import { getLanguagesByVersion, getNodeApiUrlByVersion } from "@/config";
import localeHelper from "@/shared/helpers/locale.helper";
import ArrayField from "@/shared/classes/form/fields/array-field";
import { CompanyRoutes } from "@/shared/router/company";
import baseHttp from "@/shared/http";
import Company from "@/shared/modules/company/company.model";
import { CompanyGetters } from "@/shared/store/company/company.getters";
import AssignedHseRisks from "@/shared/modules/assigned-hse-risks/assigned-hse-risks.model";
import SelectField from "@/shared/classes/form/fields/Select/select-field";
import SelectItem from "@/shared/classes/form/fields/Select/select-item";

@Component({
  components: { RiskTemplateForm, PageHeader },
  methods: { __ }
})
export default class AssignedRiskView extends Vue {
  http = baseHttp
  form: FormBase | null = null

  created() {
    this.form = new FormBase()
    .setUuid(this.$router.currentRoute.params.uuid)
    .setEndpoint(`${getNodeApiUrlByVersion()}/assigned-hse-risks`)
    .setMethod(HttpMethod.PATCH)
    .setModel(AssignedHseRisks)
    .setTranslatable(true)
    .setCurrentLanguage(getLanguagesByVersion([localeHelper.getLocale()])[0])
    .setFields([
      new SearchableField()
        .setKey('responsibleUserUuid')
        .setHideDetails(true)
        .loadItems({
          endpoint: `company/${ this.company.slug }/employees`,
          value: 'uuid',
          title: 'full_name',
          perPage: 20,
        })
        .setTitle(__('company.components.risk-assessments.selected-assign.form.responsible'))
        .setSize(FieldSizes.threeTwelfth)
        .setMeta({ class: 'view-form-input-bg-transparent' })
        .setDisabled(true),
      new Field()
        .setType(FieldTypes.datePicker)
        .setKey('assessmentDate')
        .setTitle(__('company.components.risk-assessments.selected-assign.form.assessment-date'))
        .setSize(FieldSizes.threeTwelfth)
        .setMeta({ class: 'view-form-input-bg-transparent' })
        .setDisabled(true),
      new SelectField()
        .setKey("status")
        .setItems([
          new SelectItem()
            .setValue(1)
            .setTitle(__("company.components.risk-assessments.selected-assign.form.barrier-status-options.in-progress")),
          new SelectItem()
            .setValue(2)
            .setTitle(__("company.components.risk-assessments.selected-assign.form.barrier-status-options.completed")),
        ])
        .setTitle(__("company.components.risk-assessments.selected-assign.form.barrier-status"))
        .setSize(FieldSizes.threeTwelfth)
        .setMeta({ class: 'view-form-input-bg-transparent' })
        .setDisabled(true),
      new SearchableField()
        .setKey("hseRiskCategoryUuid")
        .setEntryKey("hseRiskCategoryUuid")
        .setTitle(__("admin.components.risk-templates.form.category"))
        .loadItems({
          endpoint: `${getNodeApiUrlByVersion()}/hse-risk-categories`,
          value: "uuid",
          title: "name",
          perPage: 20
        })
        .setSize(FieldSizes.threeTwelfth)
        .setMeta({ class: 'view-form-input-bg-transparent' })
        .setDisabled(true),
      new Field()
        .setType(FieldTypes.textArea)
        .setKey(`name.${localeHelper.getLocale()}`)
        .setTitle(__("admin.components.risk-templates.form.risk-title"))
        .setSize(FieldSizes.half)
        .setMeta({ class: 'view-form-input-bg-transparent' })
        .setReadonly(true),
      new Field()
        .setType(FieldTypes.textArea)
        .setKey("name")
        .setTranslatable(true)
        .setTitle(__("admin.components.risk-templates.form.risk-title"))
        .setSize(FieldSizes.half)
        .setMeta({ class: 'view-form-input-bg-transparent' })
        .setReadonly(true),
      new ArrayField()
        .setKey("barriers")
        .setDense(true)
        .setDeleteButtonProps({ color: "transparent", elevation: 0, class: "mt-5 mr-1", placement: "start" })
        .setAddButtonProps({ isIcon: true, class: "justify-end" })
        .setJustifyChildren("justify-space-between")
        .setChildren([
          new Field()
            .setType(FieldTypes.textArea)
            .setKey(`title.${localeHelper.getLocale()}`)
            .setTitle(__("admin.components.risk-templates.form.risk-barrier-title"))
            .setSize(FieldSizes.fiveTwelfth)
            .setMeta({ class: 'view-form-input-bg-transparent' })
            .setReadonly(true),
          new Field()
            .setType(FieldTypes.textArea)
            .setKey("title")
            .setTranslatable(true)
            .setTitle(__("admin.components.risk-templates.form.risk-barrier-title"))
            .setSize(FieldSizes.fiveTwelfth)
            .setMeta({ class: 'view-form-input-bg-transparent' })
            .setReadonly(true),
          new Field()
            .setType(FieldTypes.datePicker)
            .setKey("date")
            .setSize(FieldSizes.twoTwelfth)
            .setMeta({ class: 'align-self-center view-form-input-bg-transparent' })
            .setDisabled(true)
        ])
        .setDisabled(true),
      new SelectField()
        .setKey("probability")
        .setItems([
          new SelectItem()
            .setValue(5)
            .setTitle(__("admin.components.risk-templates.form.probability-options.especially-likely")),
          new SelectItem()
            .setValue(4)
            .setTitle(__("admin.components.risk-templates.form.probability-options.very-likely")),
          new SelectItem()
            .setValue(3)
            .setTitle(__("admin.components.risk-templates.form.probability-options.likely")),
          new SelectItem()
            .setValue(2)
            .setTitle(__("admin.components.risk-templates.form.probability-options.unlikely")),
          new SelectItem()
            .setValue(1)
            .setTitle(__("admin.components.risk-templates.form.probability-options.very-unlikely")),
        ])
        .setTitle(__("admin.components.risk-templates.form.probability"))
        .setSize(FieldSizes.fiveTwelfth)
        .setMeta({ class: 'view-form-input-bg-transparent' })
        .setDisabled(true),
      new SelectField()
        .setKey("consequences")
        .setItems([
          new SelectItem()
            .setValue(5)
            .setTitle(__("admin.components.risk-templates.form.consequences-options.catastrophic")),
          new SelectItem()
            .setValue(4)
            .setTitle(__("admin.components.risk-templates.form.consequences-options.critical")),
          new SelectItem()
            .setValue(3)
            .setTitle(__("admin.components.risk-templates.form.consequences-options.severe")),
          new SelectItem()
            .setValue(2)
            .setTitle(__("admin.components.risk-templates.form.consequences-options.less-serious")),
          new SelectItem()
            .setValue(1)
            .setTitle(__("admin.components.risk-templates.form.consequences-options.low-significance")),
        ])
        .setTitle(__("admin.components.risk-templates.form.consequences"))
        .setSize(FieldSizes.fiveTwelfth)
        .setMeta({ class: "ml-4 view-form-input-bg-transparent" })
        .setDisabled(true)
    ])
    .setSubmit(false)
  }

  goBack(): void {
    this.$router.push({ name: CompanyRoutes.riskAssessmentsIndexTabAssigned })
  }

  get company(): Company {
    return this.$store.getters[CompanyGetters.getCompany];
  }
}
